var dateMap = [1750378, 339370, 6020516, 405156, 314698, 4934293, 371351, 279894, 2804405, 346837, 8115922, 419666, 331429, 4961866, 394827, 297627, 3880278, 370026, 281433, 1759058, 345938, 6019877, 412453, 313931, 4936875, 377517, 288107, 1764201, 355753, 7093650, 429714, 331045, 4954701, 395862, 303798, 3872181, 370388, 282281, 1769106, 347794, 6016294, 402731, 313943, 4936374, 379738, 296660, 2821833, 354121, 7075475, 420499, 329003, 5991003, 387757, 304490, 3881813, 371620, 281417, 1759891, 346773, 7058733, 402742, 314029, 4945322, 378290, 290213, 2825546, 363850, 8120981, 412311, 329046, 5999285, 387797, 304850, 3878565, 372389, 280138, 2796695, 338587, 7066970, 402794, 314217, 4945746, 387922, 289573, 3864139, 354891, 8123563, 410285, 320877, 5999465, 396713, 306578, 3882277, 372005, 10246733, 428630, 336566, 6006197, 403157, 315049, 4947602, 388754, 298278, 2812502, 346711, 8123606, 418650, 321237, 4953801, 395081, 304787, 3872043, 361771, 272987, 1758554, 337258, 7060309, 412580, 322377, 4938387, 379541, 288045, 3861165, 346805, 9172394, 419282, 331173, 6004042, 396618, 306325, 3880238, 361814, 273077, 1758642, 345810, 6008485, 403237, 312907, 4934807, 371883, 288090, 2812630, 355177, 11269970, 420690, 330533, 6003275, 387659, 296107, 4916571, 361901, 273258, 1760082, 347538, 7068965, 404773, 313941, 4936877, 378038, 279989, 2813354, 356041, 8142482, 421522, 331046, 5999190, 387671, 296150, 3868373, 362325, 280393, 2797203, 337555, 7058731, 402731, 305755, 4937050, 378218, 289637, 3864394, 355146, 8125077, 420501, 320813, 5991085, 387765, 304554, 3869605, 363941, 281930, 2808981, 339094, 7059790, 402774, 314037, 4937138, 378578, 290469, 3870282, 345675, 8113303, 410795, 320859, 5991126, 387946, 304978, 3880741, 363301, 273035, 1750171, 337067];

var bin = {
  YEAR_MIN: 1890,
  YEAR_MAX: 2100,
  map: dateMap,
  monthNames: ['正月', '二月', '三月', '四月', '五月', '六月', '七月', '八月', '九月', '十月', '冬月', '腊月'],
  dayNames: ['', '初一', '初二', '初三', '初四', '初五', '初六', '初七', '初八', '初九', '初十', '十一', '十二', '十三', '十四', '十五', '十六', '十七', '十八', '十九', '二十', '廿一', '廿二', '廿三', '廿四', '廿五', '廿六', '廿七', '廿八', '廿九', '三十'],
  heavenlyStems: ['甲', '乙', '丙', '丁', '戊', '己', '庚', '辛', '壬', '癸'],
  earthlyBranches: ['子', '丑', '寅', '卯', '辰', '巳', '午', '未', '申', '酉', '戌', '亥'],
  animals: ['鼠', '牛', '虎', '兔', '龙', '蛇', '马', '羊', '猴', '鸡', '狗', '猪'],
  solarPeriods: ['小寒', '大寒', '立春', '雨水', '惊蛰', '春分', '清明', '谷雨', '立夏', '小满', '芒种', '夏至', '小暑', '大暑', '立秋', '处暑', '白露', '秋分', '寒露', '霜降', '立冬', '小雪', '大雪', '冬至'],
  solarPeriodsMap: [0, 21208, 42467, 63836, 85337, 107014, 128867, 150921, 173149, 195551, 218072, 240693, 263343, 285989, 308563, 331033, 353350, 375494, 397447, 419210, 440795, 462224, 483532, 504758]
};

export default bin;
